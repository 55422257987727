import React from "react";

const ServiceContent = [
  {
    icon: "icon-target",
    title: "Software Engineering",
    // descriptions: `Tools include: Git + GitHub,
    // CLI + CI/CD, Declarative Programming, Functional Programming, Object Oriented Programming, Adaptability, Communication Skills, and a Growth Mindset`,
    delayAnimation: "",
  },
  {
    icon: "icon-desktop",
    title: "Full-stack Web Application Development",
    // descriptions: 'Tools include: JavaScript, React, Node, Express, MongoDB, and PostgreSQL',
    delayAnimation: "200",
  },
  {
    icon: "icon-mobile",
    title: "Website Design and Development",
    // descriptions: `Tools include: HTML, CSS, JavaScript, Figma, and Web Accessibility`,
    delayAnimation: "400",
  },

];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
