import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Get in touch</h4>
        {/* <p>
          Available for freelancing if the right project comes along,
          Feel free to contact me.
        </p> */}
        <ul>

          <li className="media">
            <i className="icon icon-envelope"></i>
            <a href="mailto:"><span className="media-body"></span></a>
          </li>

          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
            </span>
          </li>

          {/* End li */}

        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
